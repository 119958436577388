import React, { useEffect, useState, useMemo, useRef } from 'react'
import moment from 'moment'
import { DATE_FORMAT } from '../../../helper/constants'
import { useTranslation } from 'react-i18next'
import SortAndFilterTable from '../../shared/SortAndFilterTable'
import {
  filterByTwoDateRanges,
  getMaxDate,
  getMinDate,
  transformDateToValueOf,
} from '../../../helper/dates'
import { useTasksPageContext } from '../../../contextproviders/TasksPageContext'
import { TasksModal } from './TasksModal'

function TasksTable() {
  const { t } = useTranslation()
  const { tasks } = useTasksPageContext()
  const { data } = tasks

  const ALL_SERVICES = {
    value: 'ALL_SERVICES',
    label: t('ALL_SERVICES'),
  }
  const ALL_TYPES = {
    value: 'ALL_TYPES',
    label: t('ALL_TYPES'),
  }

  const [minDate, setMinDate] = useState(new Date())
  const [maxDate, setMaxDate] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [completedStartDate, setCompletedStartDate] = useState(new Date())
  const [completedEndDate, setCompletedEndDate] = useState(new Date())
  const [selectedService, setSelectedService] = useState(ALL_SERVICES)
  const [selectedType, setSelectedType] = useState(ALL_TYPES)

  // get task options dynamically, so that more services and types can be added later on
  const getOptions = (data, fieldName) => {
    const uniques = [...new Set(data.map((item) => item[fieldName]))]
    const options = []

    if (fieldName === 'service') {
      options.push(ALL_SERVICES)
    } else if (fieldName === 'order_type') {
      options.push(ALL_TYPES)
    }

    uniques.forEach((unique) => {
      if (!unique) return
      return [options.push({ value: unique, label: t(`${unique}`) })]
    })

    return options
  }

  const serviceOptions = useMemo(() => getOptions(data, 'service'), [data])
  const typeOptions = useMemo(() => getOptions(data, 'order_type'), [data])

  const onDateChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const onCompletedDateChange = (dates) => {
    const [start, end] = dates
    setCompletedStartDate(start)
    setCompletedEndDate(end)
  }

  const onServiceChange = (service) => {
    setSelectedService(service)
  }

  const onTypeChange = (type) => {
    setSelectedType(type)
  }

  const setDatesForFiltering = (data = []) => {
    if (data.length > 0) {
      const dates = data.map((data) => new Date(data.reg_date))
      const regDateMin = getMinDate(dates)
      const regDateMax = getMaxDate(dates)

      const completedDates = data
        .filter((row) => row.work_done_date !== null)
        .map((data) => new Date(data.work_done_date))
      const completedDayMin = getMinDate(completedDates)
      const completedDayMax = getMaxDate(completedDates)

      // use same min and max values for both date filters to prevent losing data on initial render
      // get absolute min and max values between reg_date and work_done_date
      if (regDateMin < completedDayMin) {
        setMinDate(regDateMin)
      } else {
        setMinDate(completedDayMin)
      }

      if (regDateMax > completedDayMax) {
        setMaxDate(regDateMax)
      } else {
        setMaxDate(completedDayMax)
      }

      setStartDate(regDateMin)
      setEndDate(regDateMax)
      setCompletedStartDate(completedDayMin)
      setCompletedEndDate(completedDayMax)
    }
  }

  useEffect(() => {
    setDatesForFiltering(data)
  }, [data])

  const tableColumns = [
    {
      Header: t('Date short'),
      accessor: 'reg_date',
      Cell: ({ value }) => moment(value).format(DATE_FORMAT),
      disableGlobalFilter: true,
    },
    {
      Header: t('Service'),
      accessor: 'service',
      disableGlobalFilter: true,
    },
    {
      Header: t('Site'),
      accessor: 'site.address.street_name',
      disableGlobalFilter: true,
    },
    {
      Header: t('City'),
      accessor: 'site.address.city',
      disableGlobalFilter: true,
    },
    {
      Header: 'Kategoria',
      accessor: 'work_comment',
      disableGlobalFilter: true,
    },

    {
      Header: 'Työtilauksen kuvaus',
      accessor: 'order_description',
      disableGlobalFilter: true,
    },
    {
      Header: 'work_order_performed_action',
      accessor: 'work_order_performed_action',
      disableGlobalFilter: true,
      
    },
    {
      Header: 'work_description',
      accessor: 'work_description',
      disableGlobalFilter: true,
    },
    {
      Header: t('Status'),
      accessor: 'status',
      Cell: ({ value }) => t(value),
      disableGlobalFilter: true,
    },
    {
      Header: t('Order type'),
      accessor: 'order_type',
      Cell: ({ value }) => t(value),
      disableGlobalFilter: true,
    },
  ]

  const applyFilters = (data, defaultServiceOption, defaultTypeOption) => {
    if (
      selectedService.value === defaultServiceOption.value &&
      selectedType.value === defaultTypeOption.value
    ) {
      return data
    } else if (
      selectedService.value !== defaultServiceOption.value &&
      selectedType.value === defaultTypeOption.value
    ) {
      return data.filter((d) => d.service === selectedService.value)
    } else if (
      selectedService.value === defaultServiceOption.value &&
      selectedType.value !== defaultTypeOption.value
    ) {
      return data.filter((d) => d.order_type === selectedType.value)
    } else {
      return data.filter(
        (d) =>
          d.service === selectedService.value &&
          d.order_type === selectedType.value
      )
    }
  }

  const getTableReadyData = () => {
    // Need transform multiple date values to numerical for sort to work
    const filteredData = applyFilters(data, ALL_SERVICES, ALL_TYPES)
    const temp = transformDateToValueOf(filteredData, 'reg_date')
    return transformDateToValueOf(temp, 'work_done_date')
  }

  const modalRef = useRef()
  const passRowDataToModal = (rowData) => {
    if (modalRef.current) {
      modalRef.current.updateData({
        site_address: `${rowData['site.address.street_name']},\u00A0 ${rowData['site.address.city']}`,
        order_description: rowData.order_description,
        reg_date: rowData.reg_date,
        status: rowData.status,
        work_done_date: rowData.work_done_date,
        service: rowData.service,
        work_comment: rowData.work_comment,
        work_description: rowData.work_description,
        work_order_performed_action: rowData.work_order_performed_action
      })
      modalRef.current.handleOpen()
    }
  }

  return (
    <>
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <h2 className="title is-size-4">{t('Tasks tracking')}</h2>
          </div>
        </div>
      </nav>
      <SortAndFilterTable
        columns={tableColumns}
        data={filterByTwoDateRanges(
          getTableReadyData(),
          startDate,
          endDate,
          'reg_date',
          completedStartDate,
          completedEndDate,
          'work_done_date'
        )}
        hasClickableRows
        getRowProps={(row) => ({
          onClick: () => passRowDataToModal(row.values),
        })}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        onDateChange={(dates) => onDateChange(dates)}
        completedStartDate={completedStartDate}
        completedEndDate={completedEndDate}
        onCompletedDateChange={(dates) => onCompletedDateChange(dates)}
        serviceValue={selectedService}
        serviceOptions={serviceOptions}
        handleServiceChange={onServiceChange}
        typeValue={selectedType}
        typeOptions={typeOptions}
        handleTypeChange={onTypeChange}
      />
      <TasksModal ref={modalRef} />
    </>
  )
}

export default TasksTable
