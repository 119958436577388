import React, { useState, forwardRef, useImperativeHandle } from 'react'

import { useTranslation } from 'react-i18next'
import { DATE_FORMAT, DATETIME_FORMAT } from '../../../helper/constants'
import moment from 'moment'
import LTModal from 'components/shared/LTModal'

export const TasksModal = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({
    site_address: '',
    order_description: '',
    reg_date: '',
    status: '',
    work_done_date: '',
    service: '',
    work_comment: '',
    work_description: '',
    work_order_performed_action: '',
  })

  /*
  work_description: rowData.work_description,
        work_order_performed_action: rowData.work_order_performed_action
  */

  useImperativeHandle(ref, () => ({
    updateData(newData) {
      setModalContent(newData)
    },
    handleOpen() {
      setIsModalOpen(true)
    },
    handleClose() {
      setIsModalOpen(false)
    },
  }))

  const handleClose = () => {
    setIsModalOpen(false)
  }

  const renderModalContent = () => {
    return (
      <>
        <div className="is-size-5 mb-5">{modalContent.order_description}</div>
        <div className="columns">
          <div className="column">
            <h6>{t('Date short')}:</h6>
            <p>
              {modalContent.reg_date
                ? moment(modalContent.reg_date).format(DATE_FORMAT)
                : '-'}
            </p>
          </div>
          <div className="column">
            <h6>{t('Status')}:</h6>
            <p>{t(modalContent.status)}</p>
          </div>
          <div className="column">
            <h6>{t('Resolved')}:</h6>
            <p>
              {modalContent.work_done_date
                ? moment(modalContent.work_done_date).format(DATETIME_FORMAT)
                : '-'}
            </p>
          </div>
          <div className="column">
            <h6>{t('Service')}:</h6>
            <p>{modalContent.service}</p>
          </div>
        </div>
        <div>
          <h2>{'Työn kuvaus'}:</h2>
          {modalContent.work_description ? (
            <p className="mb-5">{modalContent.work_description}</p>
          ) : (
            <p className="has-text-grey-light">
              {t('This task does not have any work description')}
            </p>
          )}
        </div>
        <br />
        <div>
          <h2>{'Tehdyn työn kommentti'}:</h2>
          {modalContent.work_order_performed_action ? (
            <p className="mb-5">{modalContent.work_order_performed_action}</p>
          ) : (
            <p className="has-text-grey-light">
              {t('This task does not have any performed action comment')}
            </p>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {isModalOpen && (
        <LTModal
          isOpen={isModalOpen}
          handleClose={handleClose}
          subHeader={modalContent.site_address}
        >
          {renderModalContent()}
        </LTModal>
      )}
    </>
  )
})
