import ContentBox from '../../shared/ContentBox'
import React, { useEffect } from 'react'
import CostsChart from './CostsChart'
import CostsSummary from './CostsSummary'
import LoadableContent from '../../shared/LoadableContent'
import { useCostsPageContext } from '../../../contextproviders/CostsPageContext'
import { PageTitle } from 'components/shared/pageTitle'
import CostsTable from './CostsTable'

function CostsPage() {
  return <CostsPageContent />
}

function CostsPageContent() {
  const { isFetchingData, checkForUpdates } = useCostsPageContext()

  useEffect(() => {
    checkForUpdates()
  }, [])

  return (
    <LoadableContent isLoading={isFetchingData}>
      <PageTitle titleId="Costs" />
      <p>Laskutusdata päivittyy kerran viikossa sunnuntaisin 18:00.</p>
      <ContentBox>
        <CostsSummary />
      </ContentBox>
      <ContentBox>
        <CostsChart />
      </ContentBox>
      <ContentBox>
        <CostsTable />
      </ContentBox>
    </LoadableContent>
  )
}

export default CostsPage
