import SortableTable from '../../shared/SortableTable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'moment/locale/fi'
import { getMaxDate, getMinDate } from '../../../helper/dates'
import { useQualityPageContext } from '../../../contextproviders/QualityPageContext'
import { TrafficLight } from '../../shared/trafficLight'
import YearPicker from 'components/shared/YearPicker'

import Rselect from 'react-select'
import { TRAFFIC_LIGHT_THRESHOLDS } from 'helper/constants'
import { YearSelection } from 'components/shared/YearSelection'
function QualityTrackingTable() {
  const { t } = useTranslation()
  const {
    quality: { data },
  } = useQualityPageContext()
  const [categoryFilter, setCategoryFilter] = useState(null)
  const [yearFilteredData, setYearFilteredData] = useState([])
  const [reportYears, setReportYears] = useState([])
  const [chosenYear, setChosenYear] = useState(null)
  const getReportYears = (data) => {
    const years = data.reduce((acc, value) => {
      const year = new Date(value.date).getFullYear()
      if (!value.date || !value.reportType || acc.includes(year)) {
        return acc
      } else {
        return [...acc, year]
      }
    }, [])
    //always show current year even if there is no data from this year
    const currentYear = new Date().getFullYear()
    const yearsWithCurrentYear = years.includes(currentYear)
      ? years
      : [...years, currentYear]
    return yearsWithCurrentYear.sort((a, b) => b - a)
  }
  const getReportCategories = (data) => {
    const categories = data.reduce((acc, value) => {
      const category = value.reportType
      if (!value.reportType || acc.includes(category)) {
        return acc
      } else {
        return [...acc, category]
      }
    }, [])
    return categories.map((category) => {
      return { value: category, label: category }
    })
  }

  const getCategoryFilteredData = (data) => {
    if (categoryFilter && categoryFilter.value) {
      return data.filter((report) => report.reportType === categoryFilter.value)
    } else {
      return data
    }
  }

  const getDataByYear = (year) =>
    data.filter((row) => {
      if (row['date'] === null) {
        return false
      }
      const reportYear = new Date(row['date']).getFullYear()
      return year === reportYear
    })

  const handleYearChange = (year) => {
    setChosenYear(year)
    setYearFilteredData(getDataByYear(year))
  }

  const resetData = () => {
    setCategoryFilter(null)
    setYearFilteredData([])
    setReportYears([])
    setChosenYear([])
  }

  useEffect(() => {
    if (data.length > 0) {
      const reportYears = getReportYears(data)
      setReportYears(reportYears)
      setChosenYear(reportYears.length > 0 ? reportYears[0] : null)
      const dataByYear = getDataByYear(new Date().getFullYear())
      setYearFilteredData(dataByYear)
      setCategoryFilter(getReportCategories(data)[0])
    } else {
      resetData()
    }
  }, [data])

  const onCategoryChange = (option) => {
    setCategoryFilter(option)
  }

  const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'Juny',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const tableColumns = [
    {
      Header: t('Site'),
      accessor: 'site',
      disableFilters: true,
      Cell: (row) => <div style={{ fontSize: '1rem' }}>{row.value}</div>,
    },
    ...MONTHS.map((month) => ({
      Header: t(month),
      accessor: `${month}`,
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value }) => getTrafficLight(value),
    })),
  ]

  const getTrafficLight = (value) => {
    if (!value || !categoryFilter) return null
    const category = categoryFilter.value
    const getFormattedValue = (category, value) => {
      switch (category) {
        case 'Pintahygienia':
          return null
        case 'Huoltoraportti':
          return <span className="rating">{value?.toFixed()}</span>
        default:
          return <span className="rating">{value?.toFixed(2)}</span>
      }
    }
    const getTrafficLights = (category, value) => {
      const thresholds = TRAFFIC_LIGHT_THRESHOLDS[category]
      const reverse = thresholds?.reverse

      const warning = reverse
        ? value > thresholds?.yellow && value <= thresholds?.red
        : value < thresholds?.green && value >= thresholds?.yellow
      const error = reverse
        ? value > thresholds?.red
        : value <= thresholds?.yellow
      switch (category) {
        case 'Pintahygienia':
          //1 green 2 yellow 3 red 4 greenred 5 greenyellow 6 yellowred 7 greenyellowred
          switch (value) {
            case 1:
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight />
                  </div>
                </>
              )
            case 2:
              return (
                <>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight warning={true} />
                  </div>
                </>
              )
            case 3:
              return (
                <div style={{ display: 'flex' }}>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight error={true} />
                  </div>
                </div>
              )
            case 4:
              return (
                <div style={{ display: 'flex' }}>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight error={true} />
                  </div>
                </div>
              )
            case 5:
              return (
                <div style={{ display: 'flex' }}>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight warning={true} />
                  </div>
                </div>
              )
            case 6:
              return (
                <div style={{ display: 'flex' }}>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight warning={true} />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight error={true} />
                  </div>
                </div>
              )
            case 7:
              return (
                <div style={{ display: 'flex' }}>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight warning={true} />
                  </div>
                  <div style={{ padding: '0px 2px' }}>
                    <TrafficLight error={true} />
                  </div>
                </div>
              )
          }

        case 'Huoltoraportti':
          return (
            <div
              style={{
                marginLeft: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <div>
                <TrafficLight warning={warning} error={error} gray={true} />
              </div>

              {getFormattedValue(category, value)}
            </div>
          )
        default:
          return (
            <>
              <div>
                <TrafficLight warning={warning} error={error} />
              </div>

              {getFormattedValue(category, value)}
            </>
          )
      }
    }
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {getTrafficLights(category, value)}
          </div>
        </div>
      </div>
    )
  }

  const getMonth = (report) => {
    const d = new Date(report.date)
    return MONTHS[d.getMonth()]
  }

  //helper function for object mapping
  const objectMap = (obj, fn) =>
    Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

  const reduceReportsByMonth = (reports) =>
    reports.reduce((acc, report) => {
      const month = getMonth(report)
      return {
        ...acc,
        [month]: [...(acc[month] || []), report],
      }
    }, {})

  const getAvg = (reports) => {
    const category = reports[0]?.reportType
    if (category === 'Huoltoraportti' || category === 'Tekninen raportti') {
      return reports.reduce((acc, report) => acc + 1, 0)
    }
    if (category === 'Pintahygienia') {
      const evaluations = reports.reduce((acc, report) => {
        const color =
          report.rating > TRAFFIC_LIGHT_THRESHOLDS[category]?.yellow &&
          report.rating <= TRAFFIC_LIGHT_THRESHOLDS[category]?.red
            ? 'warning'
            : report.rating > TRAFFIC_LIGHT_THRESHOLDS[category]?.red
            ? 'error'
            : 'ok'
        return {
          ...acc,
          [color]: [...(acc[color] || []), report],
        }
      }, {})

      const evaluationValues = Object.keys(evaluations)
      if (evaluationValues.includes('ok')) {
        if (evaluationValues.includes('warning')) {
          if (evaluationValues.includes('error')) {
            return 7
          }
          return 5
        }
        if (evaluationValues.includes('error')) {
          return 4
        }
        return 1
      }
      if (evaluationValues.includes('warning')) {
        if (evaluationValues.includes('error')) {
          return 6
        }
        return 2
      }
      if (evaluationValues.includes('error')) {
        return 3
      }
      return null
    }
    return (
      reports.reduce((acc, report) => acc + report.rating, 0) / reports.length
    )
  }

  const reduceMonthlyAverages = (site) => objectMap(site, getAvg)

  const filteredDataBySite = getCategoryFilteredData(yearFilteredData).reduce(
    (acc, report) => {
      const name = `${report['site']['name']} - ${report['site']['address']['street_name']}, ${report['site']['address']['city']}`
      return {
        ...acc,
        [name]: [...(acc[name] || []), report],
      }
    },
    {}
  )

  const monthlyDataBySite = objectMap(filteredDataBySite, reduceReportsByMonth)

  const monthlyAverageDataBySite = objectMap(
    monthlyDataBySite,
    reduceMonthlyAverages
  )

  const dataArray = Object.keys(monthlyAverageDataBySite).map((key) => ({
    ...monthlyAverageDataBySite[key],
    site: key,
  }))
  const selectStyles = {
    dropdownIndicator: (base) => ({
      ...base,
      color: '#003087',
    }),
  }
  return (
    <>
      <nav className="level">
        <div className="level-left table-title">
          <div className="level-item">
            <h2 className="title is-size-4">{t('Quality tracking')}</h2>
          </div>

          <div className="level-item">
            <YearSelection
              years={reportYears}
              chosenYear={chosenYear}
              setChosenYear={handleYearChange}
              style={{ marginRight: '40px' }}
            />
            {categoryFilter ? (
              <div className="control" style={{ width: '260px' }}>
                <Rselect
                  value={categoryFilter}
                  onChange={onCategoryChange}
                  options={getReportCategories(data)}
                  styles={selectStyles}
                />
              </div>
            ) : null}
          </div>
        </div>
      </nav>

      <div className="calendar-tracking-table">
        <SortableTable
          columns={tableColumns}
          data={dataArray}
          showBottomRowStats={false}
        />
      </div>
    </>
  )
}

export default QualityTrackingTable
